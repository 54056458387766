import React from 'react'

import Layout from '../../components/Layout'
import BlogRoll from '../../components/BlogRoll'

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>

<section className="about-header">
  <div className="about-first">
    <div className="about-header-title">
      <h1 data-aos="fade-right" data-aos-delay="400" data-aos-duration="2000">Projects
      </h1>
      </div>
    </div>
    
  </section>

      <BlogRoll />

  </Layout>
    )
  }
}
